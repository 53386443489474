import React, { Suspense } from 'react'
import { HashRouter, Route, Routes } from 'react-router-dom'
import './scss/style.scss'
import '../src/style/output.css'
import '../src/style/chatbot.css'

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Pages
const ChatBot = React.lazy(() => import('./views/pages/chatbot/Chatbot'))


const App = () => {
  return (
    <HashRouter>
      <Suspense fallback={loading}>
        <Routes>
          <Route exact path="/chatbot" name="ChatBot" element={<ChatBot />} />
        </Routes>
      </Suspense>
    </HashRouter>
  )
}

export default App

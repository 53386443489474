import 'react-app-polyfill/stable'
import 'core-js'
import React from 'react'
import { createRoot } from 'react-dom/client'
import App from './App'
import reportWebVitals from './reportWebVitals'

const root = createRoot(document.getElementById('root'))


root.render(
    <App />
)

reportWebVitals()
